import logo from './logo.svg';
import './App.css';
import "tailwindcss/tailwind.css"
import React, { Component } from 'react';
import axios from 'axios'
import { ReactSketchCanvas } from "react-sketch-canvas";
import Canvas from "./Components/Canvas"
import CanvasNew from "./Components/CanvasNew"
import Picker from "./Components/Picker"
import Excal from "./Components/exidraw/Diagram"
// import Picker from "./Components/ColorPicker"
import Navigation from "./Components/Navigation"
import Home from "./Components/Home"
import CanvasListing from "./Components/CanvasListing"
import { Route, Link, Switch, Redirect } from 'react-router-dom';
const styles = {
  border: "0.0625rem solid #9c9c9c",
  borderRadius: "0.25rem",
};

class App extends Component {
	constructor() {
		super();
		this.state = {
			artId: '',
		};
  }

  setartId = (artId) => {
		this.setState({ artId: artId });
	};
  componentDidMount(){
		axios.get(`https://thawing-thicket-87572.herokuapp.com/api/artwork/`)
		  .then(res => {
            console.log("loading")
		  }).catch(err=>console.log(err))
    }
  render() {
  return (
    <div className="App">
    <Navigation></Navigation>
    <Route path="/" exact component={Home} />
    <Route path="/list" exact component={CanvasListing} />
    <Route path="/picker" exact component={Picker} />
    <Route path="/canvas" exact component={CanvasNew} />
    <Route path="/diagram" exact component={Excal} />
    <Route path="/new" exact component={CanvasNew} />
    <Route
					path="/canvas/:id"
					render={(props) => <CanvasNew setartId={this.artId} {...props} {...this.state} />}
				/>
    <Route
					path="/new/:id"
					render={(props) => <CanvasNew setartId={this.artId} {...props} {...this.state} />}
				/>
    </div>
  );
}
}

export default App;
