import React from 'react'
import { Header, Button, Popup, Grid } from 'semantic-ui-react'
import {Link} from 'react-router-dom';
export default function NavigationNew() {
    const [eventsEnabled, setEventsEnabled] = React.useState(true)
    const [open, setOpen] = React.useState(false)
    return (
        <Popup trigger={<span>New</span>} flowing eventsEnabled={eventsEnabled}
        on='click'
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open} >
        <Grid centered divided columns={2}>
          <Grid.Column textAlign='center'>
            <Header as='h4'>Canvas</Header>
            {/* <p>
              Drawing Canvas
            </p> */}
            <Link to="/canvas" className="text-white"><Button onClick={() => setOpen(false)}>Choose</Button></Link>
          </Grid.Column>
          <Grid.Column textAlign='center'>
            <Header as='h4'>Diagram</Header>
            {/* <p>
              
            </p> */}
            <Link to="/diagram" className="text-white"><Button onClick={() => setOpen(false)}>Choose</Button></Link>
          </Grid.Column>
          
        </Grid>
      </Popup>
    )
}
