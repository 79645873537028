import React, { Component } from 'react';

class Home extends Component {
    render() {
        return (
            <div className="flex justify-center items-center h-screen flex-col home">
                <h1 className="text-8xl bg-white p-10">Drawing Bytes</h1>
            </div>
        );
    }
}

export default Home;