import React, { Component } from 'react';
import New from "./NavigationNew"
import {Link} from 'react-router-dom';
import { Icon } from 'semantic-ui-react';

class Navigation extends Component {
    render() {
        return (
            <div className="flex space-x-12 pl-10 text-lg text-white bg-blue-900 w-full pb-2 pt-2 font-bold navigation">
                <Link to="/" className="text-white">Home</Link>
                <New></New>
                <Link to="/list" className="text-white">Saved ArtWork</Link>
                {/* <Link to="/canvas" className="text-white">Draw on Canvas</Link>
                <Link to="/diagram" className="text-white">Create A Diagram</Link> */}
                <div>
                <a href="mailto:Pbannerman13@gmail.com"><Icon name='mail' inverted color='grey' size="large"/></a>
                <a href="https://www.dcartist.studio" target="_blank" rel="noreferrer"><Icon name='world' inverted color='grey' size="large"/></a>
                <a href="https://github.com/dcartist" target="_blank" rel="noreferrer"><Icon name='github' inverted color='grey' size="large"/></a>
                
                </div>
               
                {/* <a href="https://blog.drawingbytes.com/">Blog</a> */}
            </div>
        );
    }
}

export default Navigation;